<template>
  <div :class="brandClasses.wrapper" data-test-id="form-sign-in">
    <vf-notification
      v-if="notification.message"
      class="mb-4"
      :type="notification.type"
      :dismissible="false"
      @close="notification.message = ''"
    >
      <base-interpolator :content="notification.message">
        <template #reset="{ args: [message] }">
          <base-link to="/forgot-password" target="_blank" class="underlined">
            {{ message }}
          </base-link>
        </template>
        <template #contact="{ args: [message] }">
          <base-link to="/help/contact-us" target="_blank" class="underlined">
            {{ message }}
          </base-link>
        </template>
      </base-interpolator>
    </vf-notification>
    <template v-if="!compact">
      <h2 :class="brandClasses.heading">
        {{ $t.signInTitle }}
      </h2>
      <p :class="brandClasses.helpText">
        {{ $t.requiredField }}
      </p>
    </template>
    <base-form :form validate-on="change" :class="brandClasses.form" @submit="submit">
      <vf-form-field
        v-slot="{ attrs }"
        name="email"
        :rule="[validateRequired($t.email), validateEmail(), validateMaxLength(100)]"
        :hint="$t.emailFormatHint"
      >
        <vf-input
          v-model="form.email"
          v-bind="attrs"
          type="email"
          :disabled="disableEmail"
          required
          @change="handleEmailChange"
        >
          {{ $t.email }}
        </vf-input>
      </vf-form-field>
      <vf-form-field v-slot="{ attrs }" name="password" :rule="validateRequired($t.password)">
        <input-password v-model="form.password" v-bind="attrs" required class="mt-4">
          {{ $t.password }}
        </input-password>
      </vf-form-field>
      <slot name="forgot-password">
        <vf-link
          v-if="insideDialog"
          class="mt-2 w-fit"
          data-test-id="form-sign-in-link-forgot-password"
          @click="handlePasswordChange"
        >
          {{ $t.forgotPassword }}
        </vf-link>
        <base-link
          v-else
          to="/forgot-password"
          class="mt-2 underlined"
          data-test-id="form-sign-in-link-forgot-password"
        >
          {{ $t.forgotPassword }}
        </base-link>
      </slot>
      <vf-button type="submit" :size="buttonSize" :loading :class="brandClasses.submitButton">
        {{ $t.signInSubmit }}
      </vf-button>
    </base-form>
  </div>
</template>

<script lang="ts" setup>
import type { FormLocation } from '#types/gtm'
import type { BaseNotification } from '#types/notification'

const {
  compact,
  disableEmail,
  disableModal,
  email = '',
  formLocation = 'modal:single:none',
  insideDialog,
} = defineProps<{
  compact?: boolean
  disableEmail?: boolean
  disableModal?: boolean
  email?: string
  formLocation?: FormLocation
  insideDialog?: boolean
}>()

const emit = defineEmits<{
  submit: []
  forgotPassword: []
}>()

const { brandClasses, buttonSize } = useAppConfig().components.form.signIn
const { login } = useAuthStore()
const { ModalForgotPassword } = useDialogsStore()
const { validateEmail, validateMaxLength, validateRequired } = useLocalizedValidators()
const { $gtm, $t } = useNuxtApp()
const profile = useProfileStore()
const recaptcha = useRecaptcha()

const form = reactive({
  email,
  password: ''
})

const notification = reactive<BaseNotification>({
  message: '',
  type: ''
})

const loading = ref(false)

const handlePasswordChange = () => {
  emit('forgotPassword')
  if (!disableModal) ModalForgotPassword.open()
}

const handleEmailChange = () => {
  $gtm.push('authForm.onEmail', 'Login', formLocation)
}

const submit = async () => {
  try {
    notification.message = '' // remove the error message, if any
    loading.value = true

    $gtm.push('authForm.onLoginSubmit', formLocation, false)

    const token = await recaptcha.execute('login')
    await login(form.email, form.password, token)

    notification.message = $t.youHaveSuccessfullyLoggedIn
    notification.type = 'success'

    await profile.get()

    $gtm.push('user.onLoadUserData', await getUserEventData())
    $gtm.push('authForm.onLoginConfirmed', formLocation, 'Standard', false)

    emit('submit')
  }
  catch (err) {
    assertApiError(err)
    notification.message = err.message
    notification.type = 'error'
  }
  finally {
    loading.value = false
  }
}

onMounted(() => $gtm.push('authForm.onImpression', 'Login', formLocation))
</script>
